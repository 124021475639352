.navbar-incola-steps {
  background-color: #fff;
  border-color: transparent;
  font-family: 'Celias';
  color: #717171;
  margin-bottom: 0;
  padding: 15px 15px 15px;
  border-bottom: 1px solid #ccc !important;
}

.navbar-link {
  padding-right: 20px;
  font-weight: bold;
}

.navbar-link a {
  font-family: 'Celias';
  font-size: 1em;
  color: #717171;
}

.nav .navbar-link a:hover,
.nav .navbar-link a:focus {
  font-family: 'Celias';
  font-weight: bold;
  outline: 0;
  background-color: transparent;
  color: #717171;
}

.navlink-selected a {
  color: #bcbcbc;
}

.navbar-header .nav-burger {
  padding: 17px 15px;
}

@media only screen and (max-width: 769px) {
  .navbar-incola {
    border-bottom: 1px solid #ccc !important;
    padding: 10px;
  }
}
.home-menu {
  background: #fff;
  position: fixed;
  z-index: 1002;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding-top: 100px;
  color: #717171;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  -webkit-transform: translate3d(0, -125%, 0);
          transform: translate3d(0, -125%, 0);
}

.home-menu-open {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.menu-link {
  font-size: 1em;
  height: 24px;
  line-height: 24px;
  margin-bottom: 20px;
  cursor: pointer;
}

.social-links {
  position: absolute;
  right: 30px;
  bottom: 30px;
  left: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.home-menu-separate {
  margin-top: 0;
  margin-bottom: 20px;
  border: 0;
  opacity: 0.25;
  border-top: 1px solid #979797;
}

.footer-social-links {
  margin: 0 25px;
}
.information-field {
  color: #adadad;
  margin: 20px 0 5px 0;
}

.information-icon {
  width: 17px;
    margin-right: 10px;
}
.input {
  height: 50px;
  border-radius: 25px;
  border: solid 2px #dbe3e8;
  text-align: center;
  color: #565656;
  font-size: 1em;
  font-family: 'Celias-light';
  -webkit-appearance: none;
          appearance: none;
}

.input:focus {
  border: solid 2px #47acac;
}

.input-error:focus {
  border: solid 2px #ff7372;
}

.input-small {
  width: 200px;
}

.input-large {
  max-width: 400px;
  width: 100%;
}

.default-input:focus {
  border: solid 2px #ff7372;
}

.primary-input {
  border: solid 2px #47acac;
}

.iconed-input {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 400px;
}

.iconed-input img {
  position: absolute;
  padding: 16px;
  width: 50px;
}

.line::after {
  content: '';
  position: absolute;
  margin-left: 46px;
  width: 1px;
  height: 48px;
  border: solid 1px #dbe3e8;
}

.line-error::after {
  border-color: #ff7372;
}

.padding-icon {
  padding-left: 46px;
}

.no-left-border {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.flat-right-border {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-error {
  border: solid 2px #ff7372;
}

@media screen and (max-width: 786px) {
  .input-3 {
    width: 33% !important;
  }

  .input-4 {
    width: 25% !important;
  }
}

.width-100 {
  width: 100%;
}

.input-2,
.input-3,
.input-4 {
  width: 133px;
}
.custom-modal {
  box-shadow: none !important;
  border-radius: 4px !important;
}

.custom-modal-header {
  padding: 15px 15px 0;
}

.custom-modal-title {
  margin: 0px;
  text-align: right;
}

.custom-modal-title img {
  cursor: pointer;
}

.custom-modal-backdrop {
  background-color: #fff !important;
}

.custom-modal-body {
  padding: 0 15px 15px;
}

.error-wrapper {
  margin-top: 120px;
}

.error-wrapper h1 {
  margin-bottom: 50px;
}

.error-wrapper h5 {
  margin: 0;
}

.error-wrapper img {
  width: 275px;
}
.loading-wrapper{
  background: rgba(255, 255, 255, 0.7);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;  
}
.loading-circle{
  border: 5px solid #333;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-animation: move 0.7s linear infinite;
          animation: move 0.7s linear infinite;
  border-top: 5px solid #48a0a0;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: block;
}

@-webkit-keyframes move{
  to{-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}

@keyframes move{
  to{-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}
@font-face {
  font-family: 'Celias';
  src: url(/static/media/Celias-Light.05e0bb82.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Celias';
  src: url(/static/media/Celias-Hairline.e90e939f.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Celias';
  src: url(/static/media/Celias-LightItalic.146c7c21.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Celias';
  src: url(/static/media/Celias-Black.9bd8c5ba.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Celias';
  src: url(/static/media/Celias-Medium.016d3f16.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Celias';
  src: url(/static/media/Celias.4585569c.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Celias';
  src: url(/static/media/Celias-HairlineItalic.376ffb90.ttf) format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Celias';
  src: url(/static/media/Celias-Thin.4a2bc270.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Celias';
  src: url(/static/media/Celias-Italic.6275b923.ttf) format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Celias';
  src: url(/static/media/Celias-Bold.0e645bed.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Celias';
  src: url(/static/media/Celias-ThinItalic.c0eb0b05.ttf) format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Celias';
  src: url(/static/media/Celias-BlackItalic.5ae920eb.ttf) format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Celias';
  src: url(/static/media/Celias-MediumItalic.af7cab10.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Celias';
  src: url(/static/media/Celias-BoldItalic.3db076e8.ttf) format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'fontello';
  src: url(/static/media/fontello.d929fdc5.woff2) format('woff2'),
    url(/static/media/fontello.8567aa09.woff) format('woff');
  font-weight: normal;
  font-style: normal !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Celias';
  color: #464646;
}

::selection {
  background-color: #1f5b5b;
  color: white;
}

:focus {
  outline: 0;
}

.primary-btn,
.default-btn,
.secondary-btn {
  width: 206px;
  height: 50px;
  border-radius: 25px;
  color: #ffffff;
  font-family: Celias;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: all 0.2s, -webkit-transform 0.5s;
  transition: all 0.2s, transform 0.5s;
  transition: all 0.2s, transform 0.5s, -webkit-transform 0.5s;
}

.primary-btn {
  background-color: #369696;
  box-shadow: 0px 0px 20px 0.1px rgba(54, 150, 150, 0.7);
  border: 1px solid #369696;
}

.primary-btn:disabled,
.default-btn:disabled,
.secondary-btn:disabled {
  border: 1px solid #b2b2b2;
  background-color: #b2b2b2;
  box-shadow: none;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
}

.positioned-btn:disabled {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.primary-btn:disabled:hover,
.default-btn:disabled:hover,
.secondary-btn:disabled:hover {
  border: 1px solid #b2b2b2;
  background-color: #b2b2b2;
}

.primary-btn:hover {
  background-color: #47acac;
  border-color: #47acac;
}

.primary-btn:active {
  background-color: #2a8686;
  border-color: #2a8686;
}

.primary-btn-inverted {
  border: solid 2px #369696;
  color: #369696;
  background-color: #fff;
  box-shadow: none;
}

.primary-btn-inverted:hover,
.primary-btn-inverted:active {
  color: #fff;
}

.default-btn {
  background-color: #fd5c5b;
  box-shadow: 0 2px 20px 0 rgba(253, 92, 91, 0.5);
  border: 1px solid #fd5c5b;
}

.default-btn:hover {
  background-color: #ff7372;
  border-color: #ff7372;
}

.default-btn:active {
  background-color: #ed4c4b;
  border-color: #ed4c4b;
}

.secondary-btn {
  background-color: #1f5b5b;
  box-shadow: 0 2px 20px 0 rgba(31, 91, 91, 0.5);
  border: 1px solid #369696;
}

.secondary-btn:hover {
  background-color: #306f6f;
  border-color: #306f6f;
}

.secondary-btn:active {
  background-color: #154e4e;
  border-color: #154e4e;
}

.btn-small {
  width: 120px;
}

h1 {
  font-size: 2.25em;
  line-height: 35px;
}

h2 {
  font-size: 1.75em;
  line-height: 35px;
}

h3 {
  font-size: 1.55em;
  line-height: 35px;
}

h4 {
  font-size: 1.5em;
  line-height: 32px;
}

h5 {
  font-size: 1.25em;
  line-height: 29px;
}

h6 {
  font-size: 1em;
  line-height: 25px;
}

p {
  font-size: 1em;
  line-height: 25px;
  font-family: 'Celias-light';
}

.bold {
  font-weight: bold;
}

.margin-50 {
  margin-top: 50px;
}

.margin-t20 {
  margin-top: 20px;
}

.margin-25 {
  margin-top: 25px;
}

.margin-b25 {
  margin-bottom: 25px;
}

.margin-30 {
  margin-top: 30px;
}

.margin-15 {
  margin-bottom: 15px;
}

.margin-10 {
  margin-right: 10px;
}

.padding-20 {
  padding: 20px;
}

.padding-0 {
  padding: 0 !important;
}

.margin-l20 {
  margin-left: 20px;
}

.margin-r20 {
  margin-right: 20px;
}

.margin-t0 {
  margin-top: 0;
}

.margin-b0 {
  margin-bottom: 0 !important;
}

.margin-t100 {
  margin-top: 100px;
}

.margin-t80 {
  margin-top: 60px;
}

.margin-b80 {
  margin-bottom: 80px;
}

.margin-b70 {
  margin-bottom: 70px;
}

.margin-t60 {
  margin-top: 60px !important;
}

.margin-t18 {
  margin-top: 18px !important;
}

.margin-b50 {
  margin-bottom: 50px;
}

.margin-0 {
  margin: 0;
}

.pointer {
  cursor: pointer;
}

.custom-textarea {
  max-width: 398px;
  width: 100%;
  height: 149px;
  border-radius: 30px;
  border: solid 2px #dbe3e8;
  text-align: center;
  font-size: 1em;
  font-family: 'Celias-light';
  padding: 10px;
  resize: none;
}

.custom-textarea:focus {
  border: solid 2px #47acac;
}

.full-width {
  width: 100%;
}

.full-max-width {
  max-width: 100%;
}

.green {
  color: #1f5b5b;
}

.sea-green {
  color: #369696;
}

.light-grey {
  color: #515151;
}

.lightest-grey {
  color: #b6b6b6;
}

.height-125 {
  height: 125px;
}

.height-140 {
  height: 140px;
}

.height-175 {
  height: 175px;
}

.height-250 {
  height: 250px;
}

.height-90 {
  height: 90px;
}

.height-50 {
  height: 50px;
}

.width-115 {
  width: 115px;
}

.width-120 {
  width: 120px;
}

.width-145 {
  width: 145px;
}

.footer-margin {
  margin-bottom: 65px;
}

.line-height-1 {
  line-height: 1.2;
}

.underline:hover {
  text-decoration: underline;
}

.text-bold {
  font-family: 'Celias-bold';
  font-weight: bold;
}

.preload-images {
  display: none;
}

.red {
  color: #fd5c5b;
}

.strike {
  text-decoration: line-through;
}

.absolute {
  position: absolute;
}

.italic {
  font-style: italic;
}

@media only screen and (max-width: 769px) {
  .center-mobile {
    text-align: center;
  }

  .margin-t80,
  .margin-b80,
  .margin-b70 {
    margin-top: 0;
  }

  .margin-b50 {
    margin-bottom: 10px;
  }
}
